import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import services from "../../Services/services";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EmailPage = () => {
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [req, setReq] = useState([]);

  const handleButtonClick = () => {
    setReq([...req, {}]);
    formik.setFieldValue("requests", [
      ...formik.values.requests,
      { vcpu: "", ram: "", ssd: "" },
    ]);
  };

  const handleClose = () => {
    setSuccessOpen(false);
  };

  const handleDeleteClick = (index) => {
    const newDivs = req.filter((_, i) => i !== index);
    setReq(newDivs);
    formik.setFieldValue(
      "requests",
      formik.values.requests.filter((_, i) => i !== index)
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      phoneNumber: "",
      companyName: "",
      requests: [],
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full name is required*"),
      companyName: Yup.string().required("Company name is required*"),
      phoneNumber: Yup.string().required("Phone number is required*"),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Email address is required*"),
      requests: Yup.array().of(
        Yup.object({
          vcpu: Yup.string().required("VCPU is required"),
          ram: Yup.string().required("RAM is required"),
          ssd: Yup.string().required("SSD is required"),
        })
      ),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const { data, statusCode } = await services.emailPageAPI(values);
      if (!data) {
        setLoading(false);
        return;
      }
      if (statusCode === 201) {
        setSuccessOpen(true);
        setTimeout(() => {
          setSuccessOpen(false);
        }, 6000);
        setLoading(false);
      }
      // alert(JSON.stringify(values, 2, null));
      // console.log(values);
      // setSuccessOpen(true);
    },
  });

  return (
    <div className="min-h-svh font-Inter text-light-black">
      <div className="products-bg font-Inter min-h-[17em] flex items-end justify-center text-center p-8">
        <div className="flex flex-col gap-4 place-items-center">
          <h1 className="text-4xl text-primary-blue font-semibold">
            E<span className="relative text-button-color line">mail</span>
          </h1>
          <span className="text-primary-blue text-lg">
            Elevate your buisness with our cutting-edge cloud solution{" "}
          </span>
        </div>
      </div>
      <div className="flex flex-col p-4 gap-16 max-w-4xl mx-auto py-12 bg-gray-50 m-4">
        <div>
          <h1 className="text-2xl font-semibold">Hi,</h1>
          <p className="">
            We are cloud251, leading provider of innovative cloud-based
            services. We are exited to introduce you to the transformative power
            of out cloud solutions, and how they can unlock new opportunities
            for your business.
          </p>
        </div>
        <div
          className="flex flex-col gap-4
        "
        >
          <h2 className="text-2xl">What makes us different</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              className="flex flex-col gap-2 border p-4 rounded-md shadow-md bg-white
            "
            >
              <h3 className="text-black text-lg font-medium">
                Robust managed console
              </h3>
              <p>
                Our robust managed console provides a comprehensive view of your
                cloud infrastructure, making it easier than ever to manage and
                optimize your cloud services.
              </p>
            </div>
            <div className="flex flex-col gap-2 border p-4 rounded-md shadow-md bg-white">
              <h3 className="text-black text-lg font-medium">
                Self provisioning with birr
              </h3>
              <p>
                Pay with birr and online payment. No need to contact us
                everytime.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-6">
            <div className="border border-l-8 border-l-primary-blue p-4 rounded-md shadow-sm flex flex-col gap-4 bg-white">
              <h3 className="text-xl text-primary-blue font-medium underline">
                Compute
              </h3>
              <ul
                className="flex flex-col gap-1
            "
              >
                <li>VCPU</li>
                <li>RAM</li>
                <li>Elastic Storage</li>
              </ul>
            </div>
            <div className="border border-l-8 border-l-secondary-blue p-4 rounded-md shadow-sm flex flex-col gap-4 bg-white">
              <h3 className="text-xl text-primary-blue font-medium underline">
                Storage
              </h3>
              <ul className="flex flex-col gap-1">
                <li>Object Storage</li>
                <li>Block Storage</li>
                <li>FLash Array Storage</li>
              </ul>
            </div>
            <div className="border border-l-8 border-l-button-color p-4 rounded-md shadow-sm flex flex-col gap-4 bg-white">
              <h3 className="text-xl text-primary-blue font-medium underline">
                PAAS
              </h3>
              <ul className="flex flex-col gap-1">
                <li>Managed Database</li>
                <li>Biro251</li>
              </ul>
            </div>
          </div>
        </div>
        <p className="text-lg first-letter:text-2xl">
          We offer pure cloud enviroment and not managed hosting like others.
          Get access to your own VPC and architect your entire IT infrastructure
          at the tip of your fingers
        </p>

        <div className="w-full flex items-center justify-center xl:justify-start">
          <form
            onSubmit={formik.handleSubmit}
            className="flex w-full max-w-[30em] flex-col gap-4 border p-6 bg-gradient-to-br  rounded-lg shadow-lg"
          >
            <div>
              <h1 className="text-2xl font-semibold">Register</h1>
            </div>
            <hr />
            <div className="relative w-full">
              <label htmlFor="fullName" className="">
                Full name:
              </label>
              <input
                className={`w-full bg-transparent p-2 border rounded- outline-none placeholder:text-gray-300 ${
                  formik.touched.fullName && formik.errors.fullName
                    ? "border-red-500"
                    : formik.touched.fullName && !formik.errors.fullName
                    ? "border-green-500"
                    : "border-gray-400"
                }`}
                id="fullName"
                placeholder="Full name"
                type="text"
                {...formik.getFieldProps("fullName")}
              />

              {formik.touched.fullName && formik.errors.fullName ? (
                <div className="text-red-500  mt-1">
                  {formik.errors.fullName}
                </div>
              ) : null}
            </div>
            <div className="relative w-full">
              <label htmlFor="phoneNumber" className="">
                Phone number:
              </label>
              <input
                className={`w-full bg-transparent p-2 border rounded- outline-none placeholder:text-gray-300 ${
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? "border-red-500"
                    : formik.touched.phoneNumber && !formik.errors.phoneNumber
                    ? "border-green-500"
                    : "border-gray-400"
                }`}
                id="phoneNumber"
                placeholder="Phone number"
                type="text"
                {...formik.getFieldProps("phoneNumber")}
              />

              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="text-red-500  mt-1">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
            </div>
            <div className="w-full">
              <label htmlFor="email" className="">
                Email address:
              </label>
              <input
                className={`w-full bg-transparent p-2 border rounded- outline-none placeholder:text-gray-300  ${
                  formik.touched.email && formik.errors.email
                    ? "border-red-500"
                    : formik.touched.email && !formik.errors.email
                    ? "border-green-500"
                    : "border-gray-400"
                }`}
                id="email"
                placeholder="Email"
                type="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500  mt-1">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="relative w-full">
              <label htmlFor="companyName" className="">
                Company name:
              </label>
              <input
                className={`w-full bg-transparent p-2 border rounded- outline-none placeholder:text-gray-300 ${
                  formik.touched.companyName && formik.errors.companyName
                    ? "border-red-500"
                    : formik.touched.companyName && !formik.errors.companyName
                    ? "border-green-500"
                    : "border-gray-400"
                }`}
                id="companyName"
                placeholder="Company name"
                type="text"
                {...formik.getFieldProps("companyName")}
              />

              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="text-red-500  mt-1">
                  {formik.errors.companyName}
                </div>
              ) : null}
            </div>
            <button
              type="button"
              className="flex w-fit items-center space-x-2"
              onClick={handleButtonClick}
            >
              <AddCircleIcon fontSize="large" className="text-primary-blue" />
              <span className="font-medium">Add your IT request</span>
            </button>
            {formik.values.requests.map((request, index) => (
              <div className="flex gap-2 mt-2 items-center" key={index}>
                <div className="bg-primary-blue w-fit flex">
                  <input
                    className={`max-w-[3em] md:max-w-[4em] placeholder:text-xs  bg-white p-1 border outline-none placeholder:text-gray-300 ${
                      formik.touched.requests?.[index]?.vcpu &&
                      formik.errors.requests?.[index]?.vcpu
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                    name={`requests[${index}].vcpu`}
                    placeholder="VCPU"
                    type="text"
                    value={request.vcpu}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="p-2 text-xs text-white">VCPU</span>
                </div>
                <div className="bg-primary-blue w-fit flex">
                  <input
                    className={`max-w-[3em] md:max-w-[4em]  bg-white p-1 border outline-none placeholder:text-gray-300 ${
                      formik.touched.requests?.[index]?.ram &&
                      formik.errors.requests?.[index]?.ram
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                    name={`requests[${index}].ram`}
                    placeholder="RAM"
                    type="text"
                    value={request.ram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="p-2 text-xs text-white">RAM</span>
                </div>
                <div className="bg-primary-blue w-fit flex">
                  <input
                    className={`max-w-[3em] md:max-w-[4em]  bg-white p-1 border outline-none placeholder:text-gray-300 ${
                      formik.touched.requests?.[index]?.ssd &&
                      formik.errors.requests?.[index]?.ssd
                        ? "border-red-500"
                        : "border-gray-400"
                    }`}
                    name={`requests[${index}].ssd`}
                    placeholder="SSD"
                    type="text"
                    value={request.ssd}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="p-2 text-xs text-white">SSD</span>
                </div>
                <DeleteIcon
                  fontSize="medium"
                  className="text-red-500 cursor-pointer ml-2"
                  onClick={() => handleDeleteClick(index)}
                />
              </div>
            ))}
            <span className="text-sm text-gray-500">
              Memory and Storage should be stated in GB{" "}
              <span className="text-red-500">*</span>
            </span>
            <button
              className={`flex justify-center items-center gap-2 w-full rounded-md p-2 text-white shadow-lg ${
                loading
                  ? "bg-button-color/50 cursor-wait"
                  : "bg-button-color hover:bg-button-color"
              }`}
              type="submit"
              disabled={loading}
            >
              {loading && isChecked
                ? "Submitting as an enterprise"
                : loading && !isChecked
                ? "Submitting"
                : isChecked
                ? "Submit as enterprise"
                : "Submit"}

              {loading && <div className="login-Loader"></div>}
            </button>
          </form>
        </div>
      </div>
      <div className="flex items-center justify-center w-full">
        {successOpen && (
          <Alert
            className="fixed bottom-4 mx-2 z-10 drop-shadow-md flex items-center"
            severity="success"
          >
            <span className="flex items-center gap-2">
              Thank you for reaching out to us! Your request has been
              successfully submitted. We will get back to you as soon as
              possible.{" "}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default EmailPage;
